<template>
  <v-card flat class="ma-3">
    <v-row>
      <v-col cols="3" align="center" justify="center">
        <Voltar />
      </v-col>
      <v-col cols="9" align="center" justify="center">
        <span class="clav-content-title-1">{{ titulo }}</span>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <Loading v-if="!legislacaoReady" :message="'legislação'" />
        <ConsultaLeg v-else v-bind:objeto="legislacao" v-bind:titulo="titulo" v-bind:listaReg="regulaProc" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ConsultaLeg from "@/components/legislacao/consulta/ConsultaLeg";
import Loading from "@/components/generic/Loading";
import Voltar from "@/components/generic/Voltar";

export default {
  components: {
    ConsultaLeg,
    Loading,
    Voltar
  },
  data: () => ({
    idLegislacao: "",
    legislacao: {},
    titulo: "",
    regulaProc: [],
    legislacaoReady: false
  }),
  methods: {
    parseEntidades: async function (ent) {
      try {
        var entidades = "";
        for (var i = 0; i < ent.length; i++) {
          entidades = entidades + ent[i] + " ";
        }
        return entidades;
      } catch (e) {
        return {};
      }
    },
    preparaLegislacao: async function (leg) {
      try {
        var myLegislacao = {
          codigo: {
            campo: "Código do diploma",
            text: leg.codigo
          },
          data: {
            campo: "Data do diploma",
            text: leg.data
          },
          sumario: {
            campo: "Sumário",
            text: leg.sumario
          },
          fonte: {
            campo: "Fonte de legitimação",
            text: leg.fonte
          },
          link: {
            campo: "Link",
            text: leg.link
          },
          entidades: {
            campo: "Entidades",
            // text: await this.parseEntidades(leg.entidades)
            text: leg.entidades
          }
        };
        if (leg.estado == "Ativo") {
          myLegislacao.estado = {
            campo: "Estado",
            text: "Em vigor"
          };
        } else {
          myLegislacao.estado = {
            campo: "Estado",
            text: leg.estado
          };

          if (leg.dataRevogacao)
            myLegislacao.dataRevogacao = {
              campo: "Data de revogação",
              text: leg.dataRevogacao
            };
        }

        return myLegislacao;
      } catch (e) {
        return {};
      }
    }
  },
  created: async function () {
    try {
      this.idLegislacao = window.location.pathname.split("/")[2];

      // Informação sobre a legislação
      var response = await this.$request(
        "get",
        "/legislacao/" + this.idLegislacao
      );
      this.legislacao = await this.preparaLegislacao(response.data);
      this.titulo = `${response.data.tipo}  ${response.data.numero}`;

      // Processos de negócio que são regulados pela legislação em causa
      var regulaProc = await this.$request(
        "get",
        "/legislacao/" + this.idLegislacao + "/processos"
      );
      this.regulaProc = regulaProc.data;
      this.legislacaoReady = true;
    } catch (e) {
      return e;
    }
  }
};
</script>
